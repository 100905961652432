import { Component, Inject, Input, EventEmitter, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AppService,
  ContactService,
  TrustService,
} from '@softbrik/data/services';
import { QrCardRequest, QrCardResult } from '../qr-card/qr-card.component';

@Component({
  selector: 'softbrik-qr-modal',
  templateUrl: './qr-modal.component.html',
})
export class QrModalComponent {
  @Input('factory') factory: () => Promise<string>;
  @Output('close') closeEmitter = new EventEmitter<void>();
  public requestEmitter: EventEmitter<QrCardRequest> = new EventEmitter();
  public details: QrCardResult;

  constructor(
    public app: AppService,
    public trust: TrustService,
    public common: ContactService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  requestAction(action: QrCardRequest) {
    this.requestEmitter.emit(action);
  }

  close() {
    this.details = undefined;
    this.closeEmitter.emit();
  }

  onReady(result: QrCardResult) {
    this.details = result;
  }
}
