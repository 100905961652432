import { HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'What is the Admin Brik?',
    content:
      "The Admin Brik is to help the administrators of their company's Softbrik account to manage user rights for the rest of the team, handle payments and other functions like embedding support widget on their company website",
    brik: 'admin',
  },
  {
    title: 'How to create a New User?',
    content:
      "<p>How to create a New User?</p> <ol> <li>Go to the Briks and Users tab on the Admin Brik</li> <li>Click on the + New tab on the right</li> <li>Give the user's name, email id and a password</li> <li>The user will be now in a Pending state and receive an invitation email</li> <li>Once the user signs in through that email invite, their account will be activated</li> </ol>",
    brik: 'admin',
  },
  {
    title: 'How to enable Briks for a New User?',
    content:
      '<ol> <li>After setting up the User, they will reflect in the Pending State</li> <li>Click on their name and the list of available Briks will show on the right</li> <li>Click on the Enable Brik button to enable that particular Brik for the user</li> <li>The final monthly expense for the user wll be shown as cost per month on top</li> </ol>',
    brik: 'admin',
  },
  {
    title: 'How to disable Briks for a User?',
    content:
      "<ol> <li>You can go to the particular User's profile and click Disable Brik button for any Brik you wish to disable </li> <li>Important Note: The user will lose access rights immediately for that Brik but will still be charged for the rest of the current month </li>  </ol>",
    brik: 'admin',
  },
  {
    title: 'How to delete a User?',
    content:
      "<ol> <li>You can go to the particular User's profile and click de-activate on top </li> <li>The User will get archived immediately</li> </ol> <p>Important Note: The user will lose access rights immediately for that Brik but will still be charged for the rest of the current month </p>",
    brik: 'admin',
  },
  {
    title: 'How to assign Admin rights to a User?',
    content:
      "<ol> <li> Go to the User's name and click Assign Admin rights on a Brik </li> </ol>",
    brik: 'admin',
  },
  {
    title: 'How do I know my total amount payable this month?',
    content:
      '<ol> <li> Your total monthly cost, itemized by users is shown on the Home screen </li> </ol>',
    brik: 'admin',
  },
  {
    title: 'What is the Widget?',
    content:
      '<p>The widget is the mechanism for your customers to raise issues directly from your website to the Stak Brik to resolve.</p> <p>To install the widget</p> <p>1. Authorize the domain</p> <p>2. Customize the widget message, color etc</p> <p>3. Embed the code snippet on your website</p> <p>In case of any difficulty please drop a note for us to help you at support@softbrik.com</p>',
    brik: 'admin',
  },
];

export default faqs;
