import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AlloItem,
  AlloLocation,
  AlloPerson,
  PaginationRequest,
} from '@softbrik/data/models';
import { createParams } from './utils';

@Injectable({
  providedIn: 'root',
})
export class AlloService {
  public API_LINK: string = '';

  constructor(private http: HttpClient) {}

  getItems(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<AlloItem[]>(`${this.API_LINK}/items?${query}`);
  }

  getItemLocations(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<AlloLocation[]>(`${this.API_LINK}/locations?${query}`);
  }

  getItemPersons(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<AlloPerson[]>(`${this.API_LINK}/owners?${query}`);
  }
}
