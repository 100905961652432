import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Brik } from '@softbrik/data/models';
import { AppService, ContactService, MenuItem } from '@softbrik/data/services';

@Component({
  selector: 'softbrik-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input('items') items: MenuItem[];

  logo: string;
  isSandbox = false;
  collapsed = false;
  collapsedComplete = false;
  root = window.document.querySelector('softbrik-root');
  brik: Brik;
  briksCommon: (Brik & { logo: string })[] = [];
  briksAdmin: (Brik & { logo: string })[] = [];

  constructor(
    public app: AppService,
    public common: ContactService,
    public router: Router
  ) {}

  get currentBrik() {
    const name = this.router.url.replace(/^\//, '').split('/').shift();
    return name || 'dashboard';
  }

  ngOnInit() {
    this.app.getUserCommonBriks().subscribe((briks) => {
      this.brik =
        briks.find((brik) => brik.brik_id === this.currentBrik) || this.brik;
      this.briksCommon = briks
        .filter((brik) => brik !== this.brik)
        .map((brik) => {
          return {
            ...brik,
            logo: `assets/brik-icons/${brik.brik_id}.svg`,
          };
        });
    });
    this.app.getUserAdminBriks().subscribe((briks) => {
      this.brik =
        briks.find((brik) => brik.brik_id === this.currentBrik) || this.brik;
      this.briksAdmin = briks
        .filter((brik) => brik !== this.brik)
        .map((brik) => {
          return {
            ...brik,
            logo: `assets/brik-icons/${brik.brik_id}.svg`,
          };
        });
    });

    this.logo = `assets/brik-icons/${this.currentBrik}.svg`;

    this.common.isSandbox().subscribe((isSandbox) => {
      this.isSandbox = isSandbox;
    });
  }

  home() {
    if (this.collapsed) {
      this.toggleCollapsed();
    } else {
      this.router.navigateByUrl(`/${this.currentBrik}`);
    }
  }

  navigate(item: MenuItem) {
    if (this.app.sidebarHandler) {
      this.app.sidebarHandler(item);
    } else {
      this.router.navigateByUrl(item.link);
    }
  }

  openBrik(brik: Brik) {
    if (['contact', 'stak', 'survey', 'trust'].includes(brik.brik_id)) {
      this.app.navigate(brik.brik_id);
    } else {
      this.app.navigate(brik.brik_id + '/dashboard');
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      setTimeout(() => {
        this.collapsedComplete = true;
      }, 350);
      this.root.classList.add('with-sidebar--collapsed');
    } else {
      this.root.classList.remove('with-sidebar--collapsed');
      this.collapsedComplete = false;
    }
  }
}
