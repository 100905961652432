import { HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'What is the File Brik?',
    content:
      'File Brik is a fast and reliable file storing system for business needs at much lower prices vs other solutions',
    brik: 'file',
  },
  {
    title: 'How to use the File Brik?',
    content:
      'If you are signed up to the File Brik please go inside the folder named after your email and start uploading files',
    brik: 'file',
  },
  {
    title: 'How can I share material through File Brik?',
    content: 'Please click the share link button to share any files',
    brik: 'file',
  },
  {
    title: 'Can I sync any File through a File Brik client?',
    content:
      'We are soon planning for an offline File Brik client. Till then please use the File Brik online through the platform',
    brik: 'file',
  },
  {
    title: 'Can I share a Folder?',
    content:
      'As of now you can only share a file but we will add this functionality very soon',
    brik: 'file',
  },
];
export default faqs;
