import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Contact,
  Language,
  PaginatedResponse,
  PaginationRequest,
} from '@softbrik/data/models';
import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay, filter } from 'rxjs/operators';
import { createParams } from './utils';
import Bugsnag from '@bugsnag/js';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';

export interface FileDef {
  name: string;
  type: string;
  contentType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  public store: StorageKeyHandler = createKeyHandler(
    'contact',
    StorageType.LOCAL
  );
  public session: StorageKeyHandler = createKeyHandler(
    'contact',
    StorageType.SESSION
  );

  public API_LINK: string = '';

  public languages: Observable<PaginatedResponse<Language>>;
  public translatableLanguages: Observable<PaginatedResponse<Language>>;

  constructor(private http: HttpClient) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('CONTACT_API_LINK');
    }
    this.refreshLanguages();
  }

  refreshLanguages() {
    this.languages = this.getLanguages({ offset: 0, count: 999 });
    this.translatableLanguages = this.getTranslatableLanguages();
  }

  getContact(id: number) {
    return this.getContacts({
      count: 1,
      filter: JSON.stringify({ id }),
    }).pipe(map((response) => response.data?.[0]));
  }

  getContacts(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<Contact>>(
      `${this.API_LINK}/contacts?${query}`
    );
  }

  createContact(contact: Contact) {
    return this.http.post(`${this.API_LINK}/contacts`, {
      contact: { ...contact, is_active: true },
    });
  }

  updateContact(contact: Contact) {
    return this.http.put(`${this.API_LINK}/contacts/${contact.id}`, contact);
  }

  async uploadStaticFile({ name, type, file }: FileDef & { file: File }) {
    const { url } = await this.createStaticFile({
      name,
      type,
      contentType: file.type,
    }).toPromise();
    return this.http
      .put(url, file, {
        headers: new HttpHeaders({
          'Content-Type': file.type,
        }),
      })
      .toPromise();
  }

  createStaticFile(fileDef: FileDef) {
    return this.http.post<{ url: string }>(
      `${this.API_LINK}/create-static-file`,
      fileDef
    );
  }

  getStaticFile(fileDef: Exclude<FileDef, 'contentType'>) {
    return this.http.post<{ url: string }>(
      `${this.API_LINK}/get-static-file`,
      fileDef
    );
  }

  /**
   * Like get static file but catches 404 and return empty url
   */
  getImage(
    fileDef: Exclude<FileDef, 'contentType'>,
    onError?: (err?: string) => string
  ) {
    return this.getStaticFile(fileDef).pipe(
      catchError((err: string) => {
        if (err !== 'Not found') {
          return of({ url: onError ? onError(err) : null });
        }
        return Observable.throw(err);
      })
    );
  }

  deleteStaticFile(fileDef: Exclude<FileDef, 'contentType'>) {
    return this.http.post(`${this.API_LINK}/delete-static-file`, fileDef);
  }

  getLanguages(params: PaginationRequest) {
    if (!params.sortKey) {
      params.sortKey = 'name';
      params.sortDirection = 'asc';
    }
    const query = createParams(params);
    return this.http.get<PaginatedResponse<Language>>(
      `${this.API_LINK}/languages?${query}`
    );
  }

  getTranslatableLanguages() {
    return this.getLanguages({
      offset: 0,
      count: 999,
      sortKey: 'name',
      sortDirection: 'asc',
      filter: JSON.stringify({ is_for_translate: 1 }),
    });
  }

  getDefaultLanguage() {
    return this.getLanguages({ offset: 0, count: 999 }).pipe(
      map((response) => {
        const languages = response.data.filter((status) => status.is_default);
        return languages.length > 0 ? languages[0] : null;
      })
    );
  }

  isSandbox() {
    return this.getSandboxStatus()
      .pipe(map((response) => response.is_sandbox === 1))
      .pipe(shareReplay());
  }

  getSandboxStatus() {
    return this.http.get<{ is_sandbox: 0 | 1 }>(`${this.API_LINK}/sandbox`);
  }

  verifyEmail(email: string) {
    return this.http.post(`${this.API_LINK}/verify`, { email }).pipe(
      catchError((err) => {
        throw new Error(err);
      })
    );
  }

  refreshVerified() {
    return this.http.get(`${this.API_LINK}/refresh-list`);
  }
}
