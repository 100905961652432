import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TrustItem } from '@softbrik/data/models';
import Bugsnag from '@bugsnag/js';
import {
  AppService,
  ContactService,
  TrustService,
} from '@softbrik/data/services';
import { TranslateService } from '@ngx-translate/core';

import * as QRCode from 'qrcode';

export type QrCardRequest = 'copy' | 'print' | 'download';
export type QrCardResult = { url: string; qrUrl: string };

@Component({
  selector: 'softbrik-qr-card',
  templateUrl: './qr-card.component.html',
  styleUrls: ['./qr-card.component.scss'],
})
export class QrCardComponent implements OnInit {
  @Input() factory: () => Promise<string>;
  @Input() request: EventEmitter<QrCardRequest>;
  @Output() ready = new EventEmitter<QrCardResult>();
  @ViewChild('qr') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('download', { static: true })
  downloadRef: ElementRef<HTMLAnchorElement>;
  url: string;
  qrUrl: string;
  loading: boolean = true;
  private clipBoard: HTMLInputElement;

  constructor(
    public app: AppService,
    public common: ContactService,
    public translate: TranslateService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  async ngOnInit() {
    this.url = await this.factory();

    setTimeout(this.generateQR.bind(this), 250);
  }

  private generateQR() {
    QRCode.toCanvas(this.canvas.nativeElement, this.url, (error) => {
      this.loading = false;
      if (error) {
        console.log(error);
        Bugsnag.notify(error);
      } else {
        this.presentResult();
        this.listenToRequests();
      }
    });
  }

  private async presentResult() {
    this.canvas.nativeElement.classList.remove('is-hidden');
    this.qrUrl = this.toImageUrl(await this.toImageBlob());
    this.ready.emit({
      url: this.url,
      qrUrl: this.qrUrl,
    });
  }

  private listenToRequests() {
    this.request.subscribe((request: QrCardRequest) => {
      if (request === 'copy') {
        this.copyLink();
        return;
      }
      if (request === 'print') {
        this.printQr();
        return;
      }
      if (request === 'download') {
        this.downloadQr();
        return;
      }
    });
  }

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  private copyLink() {
    this.clipBoard = document.createElement('input');
    this.clipBoard.setAttribute('value', this.url);
    document.body.appendChild(this.clipBoard);
    this.clipBoard.select();
    document.execCommand('copy');
    document.body.removeChild(this.clipBoard);
    this.app.notify(this.t('component.qr.Form link copied'));
  }

  private async printQr() {
    const pageTemplate = this.app.generatePageTemplate(
      this.toImageUrl(await this.toImageBlob())
    );
    try {
      const tab = window.open(null, '_blank');
      tab.document.write(pageTemplate);
      tab.print();
      tab.document.close();
    } catch (error) {
      alert('Disable pop-up blocker to print');
    }
  }

  private async downloadQr() {
    const download = this.downloadRef.nativeElement;
    download.setAttribute('download', 'qr-code.png');
    download.href = this.toImageUrl(await this.toImageBlob());
    if (typeof download.download === 'undefined') {
      download.setAttribute('target', '_blank');
    }
    download.click();
  }

  private toImageBlob(
    type: string = 'image/png',
    quality?: number
  ): Promise<Blob> {
    return new Promise((resolve) => {
      this.canvas.nativeElement.toBlob(resolve, type, quality);
    });
  }

  private toImageUrl(blob: Blob) {
    return URL.createObjectURL(blob);
  }
}
