<!-- Message dialog -->
<softbrik-message-dialog *ngIf="app.isShowMessage"></softbrik-message-dialog>

<!-- Notifications -->
<softbrik-notification-message
  *ngIf="app.showNotification"
  [name]="app.notificationTitle"
  [text]="app.notificationText"
  [type]="app.notificationType"
  [timeout]="app.notificationTimeout"
></softbrik-notification-message>

<!-- Help -->
<softbrik-help *ngIf="app.showHelp"></softbrik-help>

<!-- Top menu -->
<softbrik-top-menu
  *ngIf="app.isAuthenticated() && !app.isFeedback"
  [items]="app.navigationTop | async | json"
  [sidebar]="app.navigationSidebar | async | json"
  (showHelp)="toggleHelp()"
  (logout)="logout()"
  (navigate)="navigateTop($event)"
  (navigate-sidebar)="navigateSidebar($event)"
></softbrik-top-menu>

<!-- Sidebar -->
<softbrik-sidebar
  *ngIf="(app.navigationSidebar | async).length"
  [items]="app.navigationSidebar | async"
></softbrik-sidebar>

<!-- <softbrik-side-menu -->
<!--   *ngIf="(app.navigationSidebar | async).length" -->
<!--   (navigate)="navigateSidebar($event)" -->
<!--   [items]="app.navigationSidebar | async | json" -->
<!-- ></softbrik-side-menu> -->

<!-- Page body -->
<router-outlet></router-outlet>

<!-- Action bar -->
<softbrik-action-bar></softbrik-action-bar>
