import { Injectable } from '@angular/core';
import * as ngTranslate from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  constructor(public translate: ngTranslate.TranslateService) {}

  async get(
    key: string,
    params: Record<string, string | number | Promise<string | number>> = {}
  ) {
    for (const [key, value] of Object.entries(params)) {
      if (value instanceof Promise) {
        const value_ = await value;
        params[key] = value_;
      }
    }
    return this.translate.get(key, params).toPromise();
  }
}
