import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService } from '@softbrik/data/services';
import { Brik } from '@softbrik/data/models';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'softbrik-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public session = this.app.session.scope('dashboard');
  public briksAll: Observable<Brik[]>;
  public briks: Observable<Brik[]>;
  public briksAdmin: Observable<Brik[]>;
  public customerName = '';

  constructor(
    public app: AppService,
    public title: Title,
    public translate: TranslateService
  ) {}

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  ngOnInit() {
    this.setCustomer();
    this.loadBriks();
    this.app.clearSidebar();
  }

  loadBriks() {
    this.app.is_loading = false;
    this.app.currentBrik = 'dashboard';
    this.briksAll = this.app.getUserBriks();
    this.briks = this.app.getUserCommonBriks();
    this.briksAdmin = this.app.getUserAdminBriks();
  }

  setCustomer() {
    const { customer_alias } = this.app.decodeToken();
    this.customerName =
      customer_alias[0].toUpperCase() + customer_alias.substring(1);
  }

  async openBrik(brikId: string) {
    this.app.currentBrik = brikId;
    this.setTitle(`Softbrik - ${await this.t(`briks.${brikId}`)}`);
    this.app.store.setItem('brik', brikId);
    if (['contact', 'file', 'stak', 'survey', 'trust'].includes(brikId)) {
      this.app.navigate(brikId);
    } else {
      this.app.navigate(brikId + '/dashboard');
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
