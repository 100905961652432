<div class="app__content">
  <div class="columns">
    <div class="column">
      <softbrik-page-header
        title="{{ 'settings.Settings' | translate }}"
      ></softbrik-page-header>
    </div>
  </div>

  <div class="columns">
    <div class="column is-4-desktop is-12-touchable">
      <div class="panel">
        <a
          class="panel-block"
          (click)="app.navigate('change-password')"
          translate
        >
          settings.Change password
        </a>
        <a
          class="panel-block"
          (click)="app.navigate('change-language')"
          translate
        >
          settings.Change language
        </a>
      </div>
    </div>
  </div>
</div>
