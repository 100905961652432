import { HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'How to use this Platform?',
    content:
      "<p><a href='https://softbrik.com/' target='_blank'>Softbrik platform</a> manages all business operations easily for you. To use a Brik click on it from this main dashboard. We will keep adding more useful Briks on the dashboard soon. </p>",
    brik: 'dashboard',
  },
  {
    title: 'How can I understand this platform easily?',
    content:
      "You can watch short video clips on the <a href='https://softbrik.com/' target='_blank'>Softbrik platform</a>, <a href='https://softbrik.com/stak' target='_blank'>Stak Brik</a> and <a href='https://softbrik.com/allo' target='_blank'>Allo Brik</a> for easy on-boarding to the platform ",
    brik: 'dashboard',
  },
  {
    title: 'How can I find more detailed Brik specific Help?',
    content:
      '<p> Enter a Brik and click on this Help icon within that Brik to find Brik specific usage instructions to support you.</p>',
    brik: 'dashboard',
  },
  {
    title: 'How can I ask for Help not covered in these sections?',
    content:
      "<p> If you need any help please use the <a href='https://softbrik.com/support' target='_blank'>Contact Us</a> widget and we would be very glad to assist you.</p>",
    brik: 'dashboard',
  },
];

export default faqs;
