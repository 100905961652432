import { HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'What is the Stak Brik?',
    content:
      'Stak Brik is a Customer Service and Engagement product. You can receive customer issues through email or a Stak widget directly embedded in your website and resolvers can resolve with intimation to the customer. You can also use Stak Brik for internal issues.',
    brik: 'stak',
  },
  {
    title: 'How to use Stak Brik?',
    content:
      '<ol> <li>Set up the email and Widget Channel</li> <li>Aware your customers to email for help to <a href=mailto:support@your_account_name.softbrik.com> support@your_account_name.softbrik.com </a> </li> <li> Ask your admin to copy the Widget from the Admin Brik and embed on your website</li> <li>Issues can reach Stak Brik through emails, Widget and through New Issues within Stak Brik itself.</li> </ol> <p>Team members with access to Stak Brik can resolve any Issue assigned to them on the dashboard</p>',
    brik: 'stak',
  },
  {
    title: 'How to Create a Stak Issue?',
    content:
      '<p>Issues can be created by 3 ways</p> <ol> <li>By emailing to <a href=mailto:support@your_account_name.softbrik.com >support@your_account_name.softbrik.com</a>. <e  > Minimum Requirement:</em> The email has to come from a valid email id and have a subject line and body</li> <li>By entering the data through the Widget. <em>Minimum Requirement:</em> The Widget body should have a message</li> <li>By entering an issue in the Stak Brik itself (only users with Stak Brik access already for the company can use this channel for now). <em>Minimum Requirement:</em> The Issue should have a Requester email id, title and description</li> </ol>',
    brik: 'stak',
  },
  {
    title: 'How to Resolve a Stak Issue?',
    content:
      "<p><p>All persons signed up to the Stak Brik will automatically appear in the Resolver drop down list</p> <p>Anyone marked resolver for an issue needs to</p> <ol> <li>Add a meaningful comment that assures the requester that the issue is resolved</li> <li>The status of an issue is 'Mark as Active' by default. The resolver needs to check the box to 'Mark as Resolved'</li> </ol>",
    brik: 'stak',
  },
  {
    title: 'How to Change Categories, Status and Severities?',
    content:
      '<p><p>We recommend not changing the Stak Brik parameters too often as it impacts all Issues. However, if you wish to change them kindly contact your company Admin for making changes',
    brik: 'stak',
  },
];
export default faqs;
