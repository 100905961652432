import { appendStyles } from '@softbrik/shared/helpers';
import { MenuItem } from '@softbrik/data/services';
import { translate as t } from '@softbrik/translate';

appendStyles(`
  .custom-icon {
    font-size: 16px;
    color: var(--color-black);
  }

  .navbar {
    box-shadow: var(--shadow-nav);
  }
`);

type RenderOptions = {
  touchOnly: boolean;
};

const DEFAULT_RENDER_OPTIONS: RenderOptions = {
  touchOnly: false,
};

export class TopMenu extends HTMLElement {
  public static observedAttributes = ['items', 'sidebar', 'title'];

  get items() {
    return JSON.parse(this.getAttribute('items') || '[]') as MenuItem[];
  }

  get sidebar() {
    return JSON.parse(this.getAttribute('sidebar') || '[]') as MenuItem[];
  }

  set items(value: MenuItem[]) {
    const value_ = typeof value === 'string' ? value : JSON.stringify(value);
    this.setAttribute('items', value_);
  }

  set sidebar(value: MenuItem[]) {
    const value_ = typeof value === 'string' ? value : JSON.stringify(value);
    this.setAttribute('sidebar', value_);
  }

  connectedCallback() {
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  private mapSidebarItems(items: MenuItem[]) {
    return items.map((item) => {
      if (!item.linkMethod || item.linkMethod === 'event') {
        item.linkMethod = 'event';
        item.event = 'navigate-sidebar';
      }
      if (item.values) {
        item.values = this.mapSidebarItems(item.values);
      }
      return item;
    });
  }

  render() {
    const sidebar = this.renderItems(this.mapSidebarItems(this.sidebar), {
      touchOnly: true,
    }).join('');
    const left = this.renderItems(
      this.items.filter((item) => item.slot === 'left'),
      DEFAULT_RENDER_OPTIONS
    ).join('');
    const right = this.renderItems(
      this.items.filter((item) => item.slot === 'right'),
      DEFAULT_RENDER_OPTIONS
    ).join('');

    const logo = '/assets/box-logo.svg';

    this.innerHTML = `
      <nav class="navbar" role="navigation">
        <div class="navbar-brand">
          <a class="navbar-item" onclick="location.href='/'">
            <img src="${logo}" alt="${t('nav.Home')}">
          </a>
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
            ${left}
            ${sidebar}
            <div class="dropdown-divider"></div>
          </div>
          <div class="navbar-end">${right}</div>
        </div>
      </nav>
    `;

    // Burger toggle
    this.querySelectorAll('.navbar-burger').forEach((burger: HTMLElement) => {
      burger.addEventListener('click', () => {
        const target = burger.closest('nav').querySelector('.navbar-menu');
        burger.classList.toggle('is-active');
        target.classList.toggle('is-active');
      });
    });

    // Toggle dropdown
    this.querySelectorAll('.has-dropdown').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        item.classList.toggle('is-active');
        window.document.addEventListener('click', (event: Event) => {
          if (item.classList.contains('is-active')) {
            const target = event.target as HTMLElement;
            const closestDropdown = target.closest('.has-dropdown');
            if (!closestDropdown || closestDropdown !== item) {
              item.classList.remove('is-active');
            }
          }
        });
      });
    });

    // Emit events
    this.querySelectorAll('[data-event]').forEach((item) => {
      const eventName = item.getAttribute('data-event');
      const eventValue = item.getAttribute('data-event-value');
      if (!eventName) return;

      item.addEventListener('click', (event: Event) => {
        event.preventDefault();

        const burger = this.querySelector('.navbar-burger');
        const target = this.querySelector('.navbar-menu');
        burger?.classList.toggle('is-active');
        target?.classList.toggle('is-active');

        const el = event.target as HTMLElement;
        el.dispatchEvent(
          new CustomEvent(eventName, {
            bubbles: true,
            detail: eventValue,
          })
        );
      });
    });
  }

  renderItems(items: MenuItem[] = [], options: RenderOptions) {
    return items.map((item) => this.renderItem(item, options));
  }

  renderItem(original: MenuItem, options: RenderOptions) {
    const item = { ...original };
    if (options.touchOnly && (!item.type || item.type === 'text')) {
      item.type = 'text-icon';
      item.data =
        item.linkMethod === 'none' ? 'fa fa-angle-down' : 'fa fa-angle-right';
    }

    const hasChildren = Array.isArray(item.values);
    const tag = item.tag || 'a';
    const style = item.style || '';
    const classes = [
      hasChildren ? 'navbar-link is-arrowless' : 'navbar-item',
      item.class,
      options.touchOnly ? 'is-hidden-desktop' : '',
    ]
      .filter(Boolean)
      .join(' ');

    let linkMethod = '';
    switch (item.linkMethod) {
      case 'js':
        linkMethod = `onclick="location.href='${item.link}'"`;
        break;
      case 'href':
        linkMethod = `href="${item.link}"`;
        break;
      case 'none':
        // noop
        break;
      case 'event':
      // passthrough
      default:
        item.event = item.event || 'navigate';
        item.eventValue = item.link;
        break;
    }

    const eventValue = item.eventValue
      ? `data-event-value="${item.eventValue}"`
      : '';
    const eventName = item.event ? `data-event="${item.event}"` : '';
    const eventAttributes = [eventName, eventValue].join(' ');

    const attributes = `class="${classes}" style="${style}" ${eventAttributes} ${linkMethod} title="${item.name}"`;

    let trigger: string;

    switch (item.type) {
      case 'image':
        trigger = `<${tag} ${attributes}><img src="${item.data}"></${tag}>`;
        break;
      case 'spacer':
        trigger = `<hr class="dropdown-divider ${item.class}">`;
        break;
      case 'icon':
      // passthrough
      case 'text-icon':
        trigger = `<${tag} ${attributes}>
            <span class="icon"><i class="${
              item.data
            }"></i></span> <span class="${
          item.type === 'icon' ? 'is-hidden-desktop' : ''
        }">${item.name}</span>
          </${tag}>`;
        break;
      case 'text':
      // passthrough
      default:
        trigger = `<${tag} ${attributes}>${item.name}</${tag}>`;
        break;
    }

    if (!item.values) {
      return trigger;
    }

    const children = item.values
      .map((item) => this.renderItem(item, options))
      .join('');

    return `
      <div class="navbar-item has-dropdown">
        ${trigger}
        <div class="navbar-dropdown is-right">
            ${children}
        </div>
      </div>
    `;
  }
}

customElements.define('softbrik-top-menu', TopMenu);
