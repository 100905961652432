import { PipeTransform, Pipe } from '@angular/core';
import { parseJSON, formatDistance, formatRelative } from 'date-fns';
import * as prettyBytes from 'pretty-bytes';

const parseAndFormat = (
  date: string,
  fn: (date: Date, now: Date) => string | undefined
) => {
  try {
    const now = new Date();
    const date_ = parseJSON(date);
    return fn(date_, now);
  } catch (error) {
    // noop
  }
};

@Pipe({ name: 'ago' })
export class TimeAgoPipe implements PipeTransform {
  transform(date: string, suffix = 'ago'): string {
    const result = parseAndFormat(date, formatDistance);
    return result ? `${result} ${suffix}` : undefined;
  }
}

@Pipe({ name: 'relative' })
export class TimeRelativePipe implements PipeTransform {
  transform(date: string): string {
    return parseAndFormat(date, formatRelative);
  }
}

@Pipe({ name: 'localedate' })
export class LocaleDatePipe implements PipeTransform {
  transform(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {
  transform(bytes: number): string {
    return prettyBytes(bytes || 0);
  }
}
