import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HealthPatient,
  HealthGroup,
  HealthDoctor,
  HelathStatus,
  HealthSeverity,
  PaginationRequest,
} from '@softbrik/data/models';
import { createParams } from './utils';

@Injectable({
  providedIn: 'root',
})
export class HealthService {
  public API_LINK: string = '';

  constructor(private http: HttpClient) {}

  getPatients(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<HealthPatient[]>(`${this.API_LINK}/patients?${query}`);
  }

  getGroups(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<HealthGroup[]>(`${this.API_LINK}/groups?${query}`);
  }

  getStatuses(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<HelathStatus[]>(`${this.API_LINK}/statuses?${query}`);
  }

  getSeverities(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<HealthSeverity[]>(
      `${this.API_LINK}/severities?${query}`
    );
  }

  getDoctors(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<HealthDoctor[]>(`${this.API_LINK}/doctors?${query}`);
  }
}
