import { appendStyles } from '@softbrik/shared/helpers';

appendStyles(`
.channel-icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.25;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.channel-icon--outlined {
  border: 1px solid var(--color-black);
  background-color: #fff;
  color: var(--color-black);
}
.channel-icon--filled {
  color: var(--channel-accent);
  background-color: rgba(var(--channel-color), 0.75);
}
.channel-icon--unknown {
  --channel-accent: #fff;
  --channel-color: var(--color-grey-light-rgb);
}
.channel-icon--admin,
.channel-icon--contact {
  --channel-accent: #fff;
  --channel-color: var(--color-purple-rgb);
}
.channel-icon--stak,
.channel-icon--trust {
  --channel-accent: #000;
  --channel-color: var(--color-yellow-rgb);
}
.channel-icon--allo,
.channel-icon--file {
  --channel-accent: #fff;
  --channel-color: var(--color-blue-rgb);
}
`);

declare type DrawMode = 'fill' | 'outline';

// This is intensionally not created "smarter"
// to allow for purge css to spot used classes
const BRIK_CLASS_MAP = {
  admin: 'channel-icon--admin',
  allo: 'channel-icon--allo',
  contact: 'channel-icon--contact',
  file: 'channel-icon--file',
  stak: 'channel-icon--stak',
  trust: 'channel-icon--trust',
  unknown: 'channel-icon--unknown',
};

const BRIK_CODE_MAP = {
  admin: 'Ab',
  allo: 'Al',
  contact: 'Cb',
  file: 'Fb',
  stak: 'Sb',
  trust: 'Tb',
  unknown: '<i class="fa fa-question"></i>',
};

export class BrikChannelIcon extends HTMLElement {
  public static observedAttributes = ['brik', 'draw-mode'];

  get brik(): string {
    return this.getAttribute('brik');
  }

  set brik(value: string) {
    this.setAttribute('brik', value);
  }

  get drawMode(): DrawMode {
    const value = this.getAttribute('draw-mode');

    return value === 'fill' || value === 'outline' ? value : 'fill';
  }

  set drawMode(value: DrawMode) {
    this.setAttribute('draw-mode', value);
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const modeClass =
      this.drawMode === 'fill'
        ? 'channel-icon--filled'
        : 'channel-icon--outlined';
    const brikClass = BRIK_CLASS_MAP[this.brik];
    const brikCode = BRIK_CODE_MAP[this.brik];

    this.innerHTML = `<span class="channel-icon ${modeClass} ${brikClass}" title="${this.brik}">${brikCode}</span>`;
  }
}

customElements.define('softbrik-channel-icon', BrikChannelIcon);
