import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [AppService],
})
export class DataAppDataServiceModule {}
