import { PaginationRequest } from '@softbrik/data/models';

const paginationDefaults = Object.freeze({
  count: 5,
  offset: 0,
});

/**
 * Create params for doing a paginated query
 */
export function createParams(
  params: PaginationRequest,
  additional: Record<string, any> = {}
) {
  return Object.entries({ ...paginationDefaults, ...params, ...additional })
    .map(([key, value]) => {
      if (key === 'filter' && value) {
        return `filter=${encodeURI(String(value))}`;
      }
      if (typeof value !== 'undefined' && value !== null && value !== '') {
        return `${key}=${value}`;
      }
    })
    .filter(Boolean)
    .join('&');
}
