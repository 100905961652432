import { appendStyles } from '@softbrik/shared/helpers';

appendStyles(`
  .page-header { padding-bottom: 1rem; }
  .page-header h1:first-letter { text-transform: capitalize; }
  .page-header__note {
    font-size: 1.125rem;
    color: var(--color-grey-light);
  }
`);

export class PageHeader extends HTMLElement {
  public static observedAttributes = ['title', 'subtitle', 'note'];

  get title() {
    return this.getAttribute('title');
  }

  set title(value) {
    this.setAttribute('title', value);
  }

  get note() {
    return this.getAttribute('note');
  }

  set note(value) {
    this.setAttribute('note', value);
  }

  get subtitle() {
    return this.getAttribute('subtitle');
  }

  set subtitle(value) {
    this.setAttribute('subtitle', value);
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = `
    <div class="page-header">
      <h1 class="title is-4">${this.title} ${
      this.note ? `<span class="page-header__note">${this.note}</span>` : ''
    }</h1>
      ${this.subtitle ? `<h2 class="subtitle is-6">${this.subtitle}</h2>` : ''}
    </div>
    `;
  }
}

customElements.define('softbrik-page-header', PageHeader);
