import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaginatedResponse,
  PaginationRequest,
  SurveySurvey,
  SurveyAnswerUpsert,
  SurveyPageElement,
  SurveyPage,
  SurveySubmission,
} from '@softbrik/data/models';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';
import { createParams } from './utils';
import { map, shareReplay } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  public store: StorageKeyHandler = createKeyHandler(
    'survey',
    StorageType.LOCAL
  );
  public session: StorageKeyHandler = createKeyHandler(
    'survey',
    StorageType.SESSION
  );

  public API_LINK: string = '';

  public rearranging = false;

  constructor(private http: HttpClient, private app: AppService) {}

  createSurvey(
    survey: Omit<
      SurveySurvey,
      | 'id'
      | 'survey_brik_submissions'
      | 'survey_brik_pages'
      | 'created_at'
      | 'updated_at'
      | 'published_at'
    >
  ) {
    return this.http.post<SurveySurvey>(this.apiUrl(`surveys`), survey);
  }

  cloneSurvey(
    surveyId: number,
    params: Partial<Omit<SurveySurvey, 'id'>> = {}
  ) {
    return this.http.post<SurveySurvey>(
      this.apiUrl(`surveys/${surveyId}/clone`),
      params
    );
  }

  getSurvey(id: number) {
    return this.http.get<SurveySurvey & { survey_brik_pages: SurveyPage[] }>(
      this.apiUrl(`surveys/${id}`)
    );
  }

  getAllSurveys() {
    return this.getSurveys({
      count: 999999,
    })
      .pipe(map((response) => response.data))
      .pipe(shareReplay());
  }

  getSurveys(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<SurveySurvey>>(
      this.apiUrl(`surveys?${query}`)
    );
  }

  updateSurvey(survey: Partial<SurveySurvey>) {
    // @ts-ignore
    const { submissions: _, ...survey_ } = survey;
    return this.http.patch<SurveySurvey>(
      this.apiUrl(`surveys/${survey.id}`),
      survey_
    );
  }

  addPage(
    survey: SurveySurvey,
    afterPageId: number = null,
    page: Partial<SurveyPage> = {}
  ) {
    return this.http.post<SurveySurvey & { survey_brik_pages: SurveyPage[] }>(
      this.apiUrl(`surveys/${survey.id}/pages`),
      { afterPageId, page }
    );
  }

  removePage(survey: SurveySurvey, pageId: number) {
    return this.http.delete<SurveySurvey & { survey_brik_pages: SurveyPage[] }>(
      this.apiUrl(`surveys/${survey.id}/pages/${pageId}`)
    );
  }

  updatePage(
    survey: SurveySurvey,
    page: Pick<SurveyPage, 'id'> & Partial<Exclude<SurveyPage, 'id'>>
  ) {
    return this.http.patch<SurveyPage>(
      this.apiUrl(`surveys/${survey.id}/pages/${page.id}`),
      page
    );
  }

  reorderPages(survey: SurveySurvey, pages: SurveyPage['id'][]) {
    return this.http.put<SurveySurvey>(
      this.apiUrl(`surveys/${survey.id}/pages/reorder`),
      { pages }
    );
  }

  addElement(
    page: SurveyPage,
    afterElementId: number = null,
    element: Partial<SurveyPageElement> = {}
  ) {
    return this.http.post<SurveyPage>(
      this.apiUrl(
        `surveys/${page.survey_brik_survey_id}/pages/${page.id}/elements`
      ),
      { afterElementId, element }
    );
  }

  removeElement(page: SurveyPage, elementId: number) {
    return this.http.delete<
      SurveyPage & { survey_brik_page_elements: SurveyPageElement[] }
    >(
      this.apiUrl(
        `surveys/${page.survey_brik_survey_id}/pages/${page.id}/elements/${elementId}`
      )
    );
  }

  updateElement(
    page: SurveyPage,
    element: Pick<SurveyPageElement, 'id'> &
      Partial<Exclude<SurveyPageElement, 'id'>>
  ) {
    return this.http.patch<SurveyPageElement>(
      this.apiUrl(
        `surveys/${page.survey_brik_survey_id}/pages/${page.id}/elements/${element.id}`
      ),
      element
    );
  }

  reorderElements(page: SurveyPage, elements: SurveyPageElement['id'][]) {
    return this.http.put<SurveyPage>(
      this.apiUrl(
        `surveys/${page.survey_brik_survey_id}/pages/${page.id}/elements/reorder`
      ),
      { elements }
    );
  }

  getUploadUri(submissionId: string, elementId: number, file: File) {
    return this.http.post<{ uri: string }>(
      this.apiUrl(`submissions/${submissionId}/elements/${elementId}/upload`),
      {
        content_type: file.type,
        ...(file.type.startsWith('audio') ? {} : { filename: file.name }),
      }
    );
  }

  uploadWithProgress(uri: string, file: File) {
    let complete = false;
    let loaded = 0;
    let progress = 0;
    let total = 0;
    return this.http
      .put(uri, file, {
        reportProgress: true,
        observe: 'events',
        headers: {
          'Content-Type': file.type,
        },
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              // noop
              break;
            case HttpEventType.ResponseHeader:
              // noop
              break;
            case HttpEventType.UploadProgress:
              total = event.total || total;
              loaded = event.loaded || loaded;
              progress = Math.round((loaded / (total + 0.0001)) * 100);
              break;
            case HttpEventType.Response:
              complete = true;
              break;
          }
          return {
            complete,
            loaded,
            progress,
            total,
          };
        })
      );
  }

  getDownloadUri(submissionId: string, elementId: number) {
    return this.http.get<{ uri: string }>(
      this.apiUrl(`submissions/${submissionId}/elements/${elementId}/download`)
    );
  }

  deleteFile(submissionId: string, elementId: number) {
    return this.http.delete<{ uri: string }>(
      this.apiUrl(`submissions/${submissionId}/elements/${elementId}/delete`)
    );
  }

  createSubmission(surveyId: number) {
    return this.http.post<SurveySubmission>(this.apiUrl(`submissions`), {
      survey_brik_survey_id: surveyId,
    });
  }

  createSurveyQRLink({
    surveyId,
    language,
  }: {
    surveyId: number;
    language: string;
  }) {
    return this.app.createPublicURL(`survey-submission/${surveyId}`, {
      language,
    });
  }

  getSubmission(id: string) {
    return this.http.get<SurveySubmission>(this.apiUrl(`submissions/${id}`));
  }

  getAllSubmissionsForSurvey(
    surveyId: number,
    filters: Record<string, any> = {}
  ) {
    return this.getSubmissionsForSurvey(surveyId, filters).pipe(
      map((response) => response.data)
    );
  }

  getSubmissionsForSurvey(surveyId: number, filters: Record<string, any> = {}) {
    return this.getSubmissions({
      count: 99999,
      filter: encodeURI(
        JSON.stringify({
          ...filters,
          survey_brik_survey_id: surveyId,
        })
      ),
    });
  }

  getSubmissions(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<SurveySubmission>>(
      this.apiUrl(`submissions?${query}`)
    );
  }

  updateSubmission(submission: SurveySubmission) {
    // @ts-ignore
    const { survey: _, ...rest } = survey;
    // TODO remove survey key and pass object directly
    // TODO fix url
    return this.http.put<SurveySurvey>(
      this.apiUrl(`surveys/${submission.id}`),
      {
        submission: rest,
      }
    );
  }

  startReorder() {
    this.rearranging = true;
  }

  endReorder() {
    this.rearranging = false;
  }

  submitPage(
    submission: SurveySubmission,
    page: Pick<SurveyPage, 'id'>,
    answers: SurveyAnswerUpsert[]
  ) {
    const payload = {
      page: { id: page.id },
      answers,
    };

    return this.http.post<SurveySubmission>(
      this.apiUrl(`submissions/${submission.id}/submit`),
      payload
    );
  }

  submitSurvey(submission: SurveySubmission) {
    return this.http.patch<SurveySubmission>(
      this.apiUrl(`submissions/${submission.id}/finalize`),
      {}
    );
  }

  private apiUrl(url: string) {
    return `${this.API_LINK}/${url}`;
  }
}
