import { Injectable } from '@angular/core';
import {
  StakIssue,
  StakIssueComment,
  StakCategory,
  StakSeverity,
  StakStatus,
  User,
  StakIssueCommentNew,
  PaginatedResponse,
  PaginationRequest,
  StakCustomer,
} from '@softbrik/data/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { createParams } from './utils';
import {
  createKeyHandler,
  StorageKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class StakService {
  public store: StorageKeyHandler = createKeyHandler('stak', StorageType.LOCAL);
  public session: StorageKeyHandler = createKeyHandler(
    'stak',
    StorageType.SESSION
  );

  public API_LINK: string = '';

  public categories: Observable<PaginatedResponse<StakCategory>>;
  public statuses: Observable<PaginatedResponse<StakStatus>>;
  public severities: Observable<PaginatedResponse<StakSeverity>>;
  public customers: Observable<PaginatedResponse<StakCustomer>>;
  public users: Observable<PaginatedResponse<User>>;

  constructor(private http: HttpClient, public app: AppService) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('STAK_API_LINK');
    }
    this.refreshCategories();
    this.refreshStatuses();
    this.refreshResolvers();
    this.refreshSeverities();
    this.refreshCustomers();
  }

  refreshResolvers() {
    this.users = this.app.getUsers({
      link: this.app.API_LINK,
      count: 100,
      sortKey: 'first_name',
      sortDirection: 'asc',
      filter: JSON.stringify({ is_active: 1 }),
    });
  }

  refreshCustomers() {
    this.customers = this.getCustomers({
      count: 1000,
      sortKey: 'name',
      sortDirection: 'asc',
      filter: JSON.stringify({ is_active: 1 }),
    });
  }

  refreshCategories() {
    this.categories = this.getCategories({
      offset: 0,
      count: 1000,
      sortKey: 'name',
      sortDirection: 'asc',
      filter: JSON.stringify({ is_active: 1 }),
    });
  }

  refreshSeverities() {
    this.severities = this.getSeverities({
      offset: 0,
      count: 1000,
      filter: JSON.stringify({ is_active: 1 }),
      sortKey: 'name',
      sortDirection: 'asc',
    });
  }

  refreshStatuses() {
    this.statuses = this.getStatuses({
      offset: 0,
      count: 1000,
      filter: JSON.stringify({ is_active: 1 }),
      sortKey: 'name',
      sortDirection: 'asc',
    });
  }

  getDefaultCategoryId() {
    return this.categories
      .pipe(
        map((categories) => {
          const cat = categories['data'].filter(
            (category: any) => category.is_default
          );
          return cat.length > 0 ? cat[0].id : null;
        })
      )
      .toPromise();
  }

  getDefaultSeverityId() {
    return this.severities
      .pipe(
        map((response: PaginatedResponse<StakSeverity>) => {
          const severities = response.data.filter(
            (severity: StakSeverity) => severity.is_default
          );
          return severities.length > 0 ? severities[0].id : null;
        })
      )
      .toPromise();
  }

  getDefaultStatusId() {
    return this.statuses
      .pipe(
        map((statuses) => {
          const stat = statuses['data'].filter((status) => status.is_default);
          return stat.length > 0 ? stat[0].id : null;
        })
      )
      .toPromise();
  }

  getIssues(params: PaginationRequest = {}) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakIssue>>(
      `${this.API_LINK}/issues?${query}`
    );
  }

  createIssueCommentPublic({
    token,
    content_type,
  }: {
    content_type: string;
    token: string;
  }) {
    const options = {
      headers: new HttpHeaders({
        Authorization: token,
        'Content-Type': 'applicaion/json',
      }),
    };
    return this.http.post<StakIssueComment>(
      `${this.API_LINK}/issue-comments-public`,
      { content_type },
      options
    );
  }

  createIssue(issue: Exclude<StakIssue, 'id'> & { content_type?: string }) {
    return this.http.post(`${this.API_LINK}/issues`, issue);
  }

  updateIssue(item: StakIssue) {
    return this.http.put(`${this.API_LINK}/issues/${item.id}`, item);
  }

  updateIssues(items: StakIssue[]) {
    return this.http.put(`${this.API_LINK}/issues/bulk`, items);
  }

  getCategories(params: PaginationRequest = {}) {
    if (!params.filter) {
      params.filter = JSON.stringify({ is_active: 1 });
    }
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakCategory>>(
      `${this.API_LINK}/categories?${query}`
    );
  }

  createCategory(item) {
    return this.http.post(`${this.API_LINK}/categories`, item);
  }

  updateCategory(item) {
    return this.http.put(`${this.API_LINK}/categories/${item.id}`, item);
  }

  getSeverities(params: PaginationRequest) {
    if (!params.filter) {
      params.filter = JSON.stringify({ is_active: 1 });
    }
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakSeverity>>(
      `${this.API_LINK}/severities?${query}`
    );
  }

  createSeverity(item) {
    return this.http.post(`${this.API_LINK}/severities`, item);
  }

  updateSeverity(item) {
    return this.http.put(`${this.API_LINK}/severities/${item.id}`, item);
  }

  createIssueComment(comment: StakIssueCommentNew & { content_type?: string }) {
    return this.http.post<StakIssueComment>(
      `${this.API_LINK}/issue-comments`,
      comment
    );
  }

  getIssueComments(
    { issue, ...params }: PaginationRequest & { issue?: StakIssue } = {
      sortKey: 'created_at',
      sortDirection: 'desc',
    }
  ) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakIssueComment>>(
      `${this.API_LINK}/issues/${issue.id}/comments?${query}`
    );
  }

  updateIssueComment(comment: StakIssueComment) {
    return this.http.put(
      `${this.API_LINK}/issue-comments/${comment.id}`,
      comment
    );
  }

  getStatuses(params: PaginationRequest) {
    if (!params.filter) {
      params.filter = JSON.stringify({ is_active: 1 });
    }
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakStatus>>(
      `${this.API_LINK}/statuses?${query}`
    );
  }

  createStatus(item) {
    return this.http.post(`${this.API_LINK}/statuses`, item);
  }

  updateStatus(item) {
    return this.http.put(`${this.API_LINK}/statuses/${item.id}`, item);
  }

  getCustomers(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<StakCustomer>>(
      `${this.API_LINK}/customers?${query}`
    );
  }

  createCustomer(item) {
    return this.http.post(`${this.API_LINK}/customers`, item);
  }

  updateCustomer(item) {
    return this.http.put(`${this.API_LINK}/customers/${item.id}`, item);
  }

  uploadAudioFeedback(link: string, feedback: Blob) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': feedback.type,
      }),
    };
    return this.http.put(link, feedback, options);
  }

  getFile(id: number, isDownload: boolean) {
    return this.http.post<{ url: string }>(`${this.API_LINK}/get-file`, {
      id,
      isDownload,
    });
  }

  transcribeAudioFeedback(issueId: number) {
    return this.http.post(`${this.API_LINK}/transcribe-audio-feedback`, {
      issue_id: issueId,
    });
  }
}
